import Header from "src/components/header/Header"
import styles from "./AboutUs.module.css"
import {ReactComponent as BgPicture} from "src/images/background_image.svg"
const AboutUs = () =>{
  return(
    
    
    <div>
<Header />
    <div className={styles.content}>
    <div className={styles.bgPictureContainer}>
          <BgPicture viewBox="0 0 4121.7 854" className={styles.bgPicture}/>
       
            
           
        </div><div className={styles.pageTitle}>
          <h1>ABOUT US</h1>
      
          </div>
       
    
    </div>
    </div>
  );
}

export default AboutUs;