import styles from "./Header.module.css";
import { NavLink, useNavigate } from "react-router-dom";
import Logo from "../../images/Logo.png";
import MenuIcon from "@mui/icons-material/Menu";
import { React, useEffect, useState } from "react";
import { Typography } from "@mui/material";

const Header = () => {
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  console.log(status);
  const toggle = () => {
    if (status === false) {
      setStatus(true);
    } else {
      setStatus(false);
    }
  };
  const currLink = window.location.pathname;
  console.log(currLink);
  return (
    <div className={styles.sticky}>
      <div className={styles.header}>
        <div className={styles.title}>
          <img
            className={styles.logo}
            src={Logo}
            alt="The logo of the company"
            onClick={() => {
              navigate("/");
            }}
          />
        </div>

        <div
          className={`${styles.mobilemodal} ${
            status === true
              ? styles.visiblemobilemodal
              : styles.hiddenmobilemodal
          }`}
        >
          <NavLink className={styles.headercontent} to="/">
            Home
          </NavLink>
          <NavLink className={styles.headercontent} to="/ourservices">
            Services
          </NavLink>
          <NavLink className={styles.headercontent} to="/portfolio">
            Portfolio
          </NavLink>
          <NavLink className={styles.headercontent} to="/aboutus">
            About Us
          </NavLink>
        </div>
        <div className={styles.mainheadercontent}>
          <NavLink className={styles.headercontent} to="/">
            {currLink === "/" ? (
              <Typography color="red" fontSize={18} fontWeight="Bold">
                Home
              </Typography>
            ) : (
              <Typography color="white" fontSize={18} fontWeight="Bold">
                Home
              </Typography>
            )}
          </NavLink>
          <NavLink className={styles.headercontent} to="/ourservices">
            {currLink === "/ourservices" ? (
              <Typography color="red" fontSize={18} fontWeight="Bold">
                Services
              </Typography>
            ) : (
              <Typography color="white" fontSize={18} fontWeight="Bold">
                Services
              </Typography>
            )}
          </NavLink>
          <NavLink className={styles.headercontent} to="/portfolio">
            {currLink === "/portfolio" ? (
              <Typography color="red" fontSize={18} fontWeight="Bold">
                Portfolio
              </Typography>
            ) : (
              <Typography color="white" fontSize={18} fontWeight="Bold">
                Portfolio
              </Typography>
            )}
          </NavLink>
          <NavLink className={styles.headercontent} to="/aboutus">
            {currLink === "/aboutus" ? (
              <Typography color="red" fontSize={18} fontWeight="Bold">
                About Us
              </Typography>
            ) : (
              <Typography color="white" fontSize={18} fontWeight="Bold">
                About Us
              </Typography>
            )}
          </NavLink>
        </div>
        <div className={styles.buttonwrapper}>
          <div className={styles.contact}>Contact Us</div>
        </div>
        <div className={styles.miniheader} onClick={toggle}>
          <MenuIcon fontSize="large" />
        </div>
      </div>
    </div>
  );
};

export default Header;
