const NoPage = () =>{
  return(
    <div>
      <h1>
        ERROR, THIS PAGE DOES NOT EXIST :(
      </h1>
    </div>
  );
}

export default NoPage;