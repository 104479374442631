import styles from './Portfolio.module.css'
import Header from "src/components/header/Header"
import {ReactComponent as BgPicture} from "src/images/background_image.svg"
const Products =() =>{
  return( 
    <div>
<Header />
    <div className={styles.content}>
    <div className={styles.bgPictureContainer}>
          <BgPicture viewBox="0 0 4121.7 854" className={styles.bgPicture}/>
        </div>
        <div className={styles.pageTitle}>
          <h1>PORTFOLIO</h1>
      
          </div>
       
    
    </div>
    </div>
  );
}

export default Products;