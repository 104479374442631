import "./App.css";
import Homepage from "./pages/homepage/Homepage";
import AboutUs from "./pages/aboutus/AboutUs";
import OurServices from "./pages/ourservices/OurServices";
import Header from "./components/header/Header";
import NoPage from "./pages/noPage/NoPage";
import Portfolio from "./pages/portfolio/Portfolio";
import { BrowserRouter, Routes, Route } from "react-router-dom";

function App() {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Header />} />
          <Route index element={<Homepage />} />
          <Route path="aboutus" element={<AboutUs />} />
          <Route path="ourservices" element={<OurServices />} />
          <Route path="portfolio" element={<Portfolio />} />
          <Route path="*" element={<NoPage />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}

export default App;
